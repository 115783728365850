@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@layer base {
  :root {
    --colorPrimary: #0582ef;
    --colorSecondary: #f5faff;
    --colorSecondaryWithOpacity: #f5faff80;

    --colorSuccess: #12b76a;
    --colorWarning: #f79009;
    --colorError: #f04438;
    --colorTextBase: #101828;
    --background: #f9fafb;
    --colorBorder: #d9d9d9;
    --colorLightBorder: #eaecf0;
    --grayText: #475467;

    --loginBg: #e6f4ff;
  }
}

html,
body,
#root,
body > header {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
}

body {
  min-height: 100% !important;
  max-height: 100% !important;
}

html {
  font-family:
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
div,
span,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
input,
textarea,
button,
label {
  font-family: 'Inter', 'Open Sans', sans-serif;
}

code {
  font-size: 0.95em;
  border-radius: 8px;
}

code:not([class]),
code[class=''] {
  font-weight: 700;
}

code:not([class])::before {
  content: '`';
}

/* Add single quote after */
code:not([class])::after {
  content: '`';
}

ol {
  list-style: decimal;
  padding-left: 1.5rem;
  white-space: normal;
  margin-top: 0.5rem;
}

ul {
  list-style: inside;
  padding-left: 1.5rem;
  white-space: normal;
  margin-top: 0.5rem;
}

ul > li,
ol > li {
  margin-top: 0.5rem;
}

ul > li:first-of-type,
ol > li:first-of-type {
  margin-top: 0;
}

h1 {
  font-size: 2.25em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.25em;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
  line-height: 1.2;
}

blockquote {
  --tw-border-opacity: 1;
  border-color: rgba(155, 155, 155, var(--tw-border-opacity));
  border-left-width: 2px;
  line-height: 1.5rem;
  margin: 0;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  display: grid;
}

a {
  color: var(--colorPrimary);
  text-decoration: none;
}

/* Auth components */
.ant-upload-list-item-actions {
  display: none;
}

.amplify-button:focus {
  box-shadow: none;
}
.amplify-input {
  border-radius: 8px;
  padding: 8px 14px;
}

.amplify-input:focus {
  box-shadow: 0 0 0 2px rgba(5, 175, 255, 0.1);
}

.amplify-passwordfield .amplify-button {
  padding: 8px 14px;
  border-color: #d9d9d9;
}

.amplify-label {
  font-size: 14px;
  font-weight: 500;
}

.amplify-field {
  gap: 6px;
}

label {
  font-weight: 500;
}

[data-amplify-container] {
  max-width: 560px !important;
  width: 100% !important;
}

[data-amplify-theme] {
  width: 100%;
}

[data-amplify-router] {
  border-radius: 8px;
  max-width: 560px;
  width: 100%;
  padding: 32px 40px 0 40px;
}

@media only screen and (max-width: 540px) {
  [data-amplify-router] {
    padding: 16px 20px 0 20px;
  }
}

[data-amplify-form] {
  padding: 0 !important;
  display: none;
}

.amplify-tabs__list {
  display: none !important;
}

.amplify-tabs__panel {
  padding: 0;
}

.amplify-flex .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.amplify-flex .ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background-color: whitesmoke;
  border-radius: 50%;
  border: none;
  justify-content: center;
}

.amplify-flex .ant-checkbox:hover .ant-checkbox-inner {
  border-color: inherit;
  box-shadow: none;
}

.amplify-flex .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--colorBorder);
}

.amplify-flex .ant-checkbox-wrapper-checked:hover .ant-checkbox .ant-checkbox-inner {
  background-color: var(--colorBorder) !important;
}

.amplify-flex .ant-checkbox-inner {
  margin-right: 4px !important;
}

.amplify-alert--error {
  border: 1px solid #ffc1b5;
  border-radius: 10px;
}

.amplify-alert--error .amplify-alert__dismiss {
  display: none;
}

.amplify-alert--error .amplify-alert__icon {
  color: #f04438;
}

.amplify-remove-form .amplify-flex fieldset {
  display: none;
}
.amplify-remove-form .amplify-flex .amplify-button {
  display: none;
}

.cs-message__content-wrapper {
  max-width: 100%;
}

.markdown-custom code {
  overflow-x: auto;
}

.message-list .cs-overlay::before {
  background: transparent;
}

.stop-action .cs-typing-indicator__indicator {
  display: none;
}

.ant-slider-dot {
  display: none;
}

/* Chat components */
.cs-avatar > img {
  object-fit: cover;
}
.cs-conversation {
  background: transparent;
  border-radius: 8px;
  font-size: 16px;
  padding: 0.5em 0.8em;
}

.ps__rail-y:focus {
  background-color: transparent !important;
}

.ps__thumb-y {
  background-color: var(--colorPrimary) !important;
  opacity: 0.1 !important;
}

.cs-conversation:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

.cs-conversation--active,
.cs-conversation--active .cs-conversation__name {
  background-color: var(--colorSecondaryWithOpacity) !important;
  color: var(--colorPrimary) !important;
}

.cs-conversation--active:active {
  background-color: var(--colorSecondaryWithOpacity) !important;
}

.cs-conversation:hover,
.cs-conversation:active {
  background-color: var(--colorSecondaryWithOpacity) !important;
}

.cs-message-input__content-editor-wrapper {
  padding: 0;
}

.cs-message-input__content-editor {
  border: 1px solid var(--colorBorder);
  border-radius: 8px;
  padding: 0.6em 0.9em;
}

.cs-message-input__content-editor:focus,
.cs-message-input__content-editor:active {
  border: 1px solid var(--colorPrimary);
}

.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor,
.cs-message-input__content-editor-container {
  background-color: transparent;
}

.cs-message-input__content-editor:hover {
  border-color: var(--colorPrimary);
}

:root[style*='--colorDisabled'] .cs-message-input__content-editor {
  border-color: var(--colorDisabled) !important;
}

.cs-button--send {
  color: var(--colorPrimary);
}

.cs-button--send:disabled {
  color: var(--colorBorder);
}

.cs-message-input--disabled .cs-message-input__content-editor-wrapper {
  background-color: transparent;
}

.cs-message-input--disabled .cs-message-input__content-editor:hover {
  pointer-events: none;
  border-color: var(--colorBorder);
}

.cs-message-input--disabled .cs-message-input__content-editor {
  background-color: rgba(0, 0, 0, 0.04);
}

.cs-conversation-header {
  background-color: var(--background);
}

.cs-conversation-header .cs-conversation-header__user-name {
  background-color: transparent;
}

.cs-message--incoming .cs-message__content {
  background-color: var(--background);
  border: solid 1px var(--colorLightBorder);
}

.cs-typing-indicator__text {
  color: var(--colorPrimary);
}

.simple-chat {
  .cs-message-list__scroll-wrapper > .cs-message,
  .cs-message-list__scroll-wrapper > .cs-message-group {
    max-width: 100%;
    .cs-message__content-wrapper {
      margin-top: 1.5rem;
    }
  }
}

.cs-message--outgoing .cs-message__content {
  background-color: var(--colorPrimary);
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

[data-cs-message-list] {
  padding-left: 1.2em;
}

@media only screen and (max-width: 768px) {
  [data-cs-message-list] {
    padding: 0;
  }
}

.cs-message.cs-message--outgoing.cs-message--single:not(:first-child) {
  margin-top: 1.5rem;
}

.cs-message-list__scroll-wrapper {
  max-width: 873px;
  margin: 0 auto;
}

.ant-pagination-prev {
  margin-top: auto !important;
}

.markdown-custom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.markdown-custom table {
  width: 100%;
  border-collapse: collapse;
  margin: 8px 0;
  table-layout: auto;
  background: white;
}

.markdown-custom th,
.markdown-custom td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.markdown-custom th {
  background-color: #f4f4f4;
}

.h-100vh-100px {
  height: calc(100vh - 100px);
}

pre {
  border-radius: 5px;
  overflow-x: auto;
  margin: 0.5em 0;
}

.feedback-modal {
  .ant-form-item-label {
    font-size: 1rem;
  }
  .rate-container {
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
    .ant-rate {
      font-size: 2rem;
      li {
        margin: 0.75rem;
        svg {
          stroke: currentColor;
          stroke-width: 2;
        }
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-around;
    .ant-btn {
      padding: 1rem;
    }
  }
}

.rate-container {
  display: flex;
  justify-content: center;
  .ant-rate {
    color: var(--colorPrimary);

    .ant-rate-star-zero {
      .ant-rate-star-second {
        color: var(--colorBorder);
      }
    }
    font-size: 1.7rem;
    li {
      margin: 0.5rem;
    }
  }
}

/* FONTS */

h3.ant-typography {
  font-size: 24px !important;
}

h4.ant-typography,
h5.ant-typography {
  font-size: 20px !important;
}

@media only screen and (max-width: 768px) {
  h3.ant-typography {
    font-size: 20px !important;
  }

  h4.ant-typography,
  h5.ant-typography {
    font-size: 18px !important;
  }

  .ant-table {
    font-size: 12px !important;
  }

  .ant-menu,
  .ant-menu-item,
  .ant-menu-submenu-title,
  .ant-tabs-tab {
    font-size: 14px !important;
  }

  .ant-input,
  .ant-select-item,
  .ant-select-selector,
  .amplify-input,
  .cs-message-input__content-editor-container {
    font-size: 14px !important;
  }

  .ant-form-item-label > label {
    font-size: 12px !important;
  }

  .amplify-label {
    font-size: 12px !important;
  }

  .ant-btn-lg {
    font-size: 16px !important;
  }

  .ant-btn,
  .amplify-button {
    font-size: 14px !important;
  }

  .cs-conversation {
    font-size: 14px !important;
  }

  .text-lg {
    font-size: 1rem;
  }
}

.hide-year-in-date-picker .ant-picker-header-view .ant-picker-year-btn {
  display: none;
}

.ant-pagination {
  white-space: nowrap;
}

.ant-pagination > li {
  margin-top: 0 !important;
}

.ant-pagination-prev-button-stick-left .ant-pagination-prev {
  margin-right: auto !important;
}

.ant-pagination-next-button-stick-right .ant-pagination-next {
  margin-left: auto !important;
}

.apex-charts-cursor .apexcharts-pie,
.apex-charts-cursor .apexcharts-bar-area,
.apex-charts-cursor .apexcharts-legend-series {
  cursor: pointer !important;
}

.ant-btn[disabled] {
  cursor: not-allowed !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-dropdown-menu-item {
  padding: 0 !important;
  margin: 5px !important;
}

.ant-dropdown-menu-title-content > :first-child {
  min-height: 40px;
  width: 100%;
  display: flex;
  justify-content: start;
}
